<template>
  <base-bg>
    <div>
      <section
        id="introPage"
        class="page d-flex align-items-center justify-content-center"
      >
        <div>
          <h1 class="text-center text-handwriting">Mooi</h1>
          <p class="my-2 mx-auto text-center">
            im <b>{{ season }}</b>
          </p>
          <img
            id="logoIntro"
            class="my-5 mx-auto d-block"
            src="../../assets/images/logos/logo_neu.svg"
            alt=""
          />

          <h2 class="fst-italic my-5 text-center text-libre">
            Klimaschutz <br />mit Genuss
          </h2>
          <p class="small mt-5 mb-3 text-center">gefördert von</p>
          <div class="row justify-content-around mx-auto my-3">
            <img
              id="bingo-logo"
              src="../../assets/images/logos/BINGO-Logo.jpg"
              alt=""
            />
          </div>
          <div class="row justify-content-around mx-auto my-3">
            <img
              id="skr-logo"
              src="../../assets/images/logos/skr_logo.png"
              alt=""
            />
          </div>
        </div>
      </section>
    </div>
  </base-bg>
</template>

<script>
import seasonHelper from "../../assets/helpers/converter.js";
export default {
  name: "introPage",
  computed: {
    season() {
      return seasonHelper.getSeasonName();
    },
  },
  mounted() {
    setTimeout(() => {
      this.$router.replace({ name: this.$routeNames.routeMainPage });
    }, 4000);
  },
};
</script>

<style scoped>
#logoIntro {
  width: 120px;
  height: 120px;
}

p.small {
  font-size: 0.75rem;
}

h1 {
  font-size: calc(2.5rem + 2.5vw);
}

#bingo-logo {
  width: 80px;
  height: 100%;
}

#skr-logo {
  height: 100%;
  width: 200px;
}
</style>
